import React from 'react';

import { Logo } from '@motorway/mw-highway-code';

import styles from './AppLoading.module.scss';

const AppLoading = () => (
  <div className={styles.component}>
    <div className={styles.chevron} />
    <Logo data-thc-logo />
  </div>
);

export default AppLoading;
